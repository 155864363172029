import React from "react"
import styled from "styled-components"
import { graphql, useStaticQuery } from "gatsby"
import Img from "gatsby-image"

import { Section, Container } from "../global"

const Features = () => {

    const image = useStaticQuery(graphql`
    query {
      gartner: file(sourceInstanceName: { eq: "graphics" }, name: { eq: "gartner" }) {
        childImageSharp {
          fluid(maxWidth: 1000) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `)
  console.log(image)

  return(
  <Section id="features">
    <StyledContainer>
      <Subtitle></Subtitle>
      <SectionTitle>
        Never email another confidential document
{/*        <br />
        Protect and defend your trade secrets. */}
      </SectionTitle>
      <FeaturesGrid>
        <FeatureItem>
          <FeatureTitle>Live Collaboration</FeatureTitle>
          <FeatureText>
            Empower teams to co-edit documents with visibility of
            edits, comments, votes and approvals - in a secure, fully-logged, no-copy,
            no-print environment.
          </FeatureText>
        </FeatureItem>
        <FeatureItem>
          <FeatureTitle>Audit History</FeatureTitle>
          <FeatureText>
            View the full history of views, edits, deletes, comments,
            votes, approvals, prints, and merges by every user -
            in a secure ledger that can't be overwritten.
          </FeatureText>
        </FeatureItem>
        <FeatureItem>
          <FeatureTitle>Version History</FeatureTitle>
          <FeatureText>
            View content as it existed at any point in time: fast-forward
            or rewind to see the effect of every change and who made it.
          </FeatureText>
        </FeatureItem>
        <FeatureItem>
          <FeatureTitle>Control the "Who"</FeatureTitle>
          <FeatureText>
            Define which users and teams have access to what content,
            to the project and/or the individual document level.
          </FeatureText>
        </FeatureItem>
        <FeatureItem>
          <FeatureTitle>Control the "What"</FeatureTitle>
          <FeatureText>
            Limit what content each user or team can see: a whole
            document or selected parts - everything else is <span style={{ color: `white`, backgroundColor: `black` }}>&nbsp;redacted&nbsp;</span>.
          </FeatureText>
        </FeatureItem>
        <FeatureItem>
          <FeatureTitle>Control the "When"</FeatureTitle>
          <FeatureText>
            Limit access to content by time of day and day of week,
            and automatically cut off access after the project end date.
          </FeatureText>
        </FeatureItem>
        <FeatureItem>
          <FeatureTitle>Control the "Where"</FeatureTitle>
          <FeatureText>
            Limit access to content and ability to print by IP address,
            geolocation, and/or the specific client machine being used.
          </FeatureText>
        </FeatureItem>
        <FeatureItem>
          <FeatureTitle>Control the "How"</FeatureTitle>
          <FeatureText>
            Limit how each user or team can act on content:
            view, edit, delete, comment, vote, approve, print, and/or merge.
          </FeatureText>
        </FeatureItem>
      </FeaturesGrid>
      <ImageWrapper>
        <br />
        <StyledImage fluid={image.gartner.childImageSharp.fluid} alt="" />
      </ImageWrapper>
    </StyledContainer>
  </Section>
  )
}

export default Features

const StyledContainer = styled(Container)`
  padding-bottom: 20px;
`

const SectionTitle = styled.h3`
  color: ${props => props.theme.color.primary};
  display: flex;
  justify-content: center;
  margin: 0 auto 40px;
  text-align: center;
`

const Subtitle = styled.h5`
  font-size: 16px;
  color: ${props => props.theme.color.accent};
  letter-spacing: 0px;
  margin-bottom: 12px;
  text-align: center;
`

const FeaturesGrid = styled.div`
  max-width: 670px;
  display: grid;
  grid-template-columns: 1fr 1fr;
  margin: 0px auto;
  grid-column-gap: 40px;
  grid-row-gap: 35px;
  @media (max-width: ${props => props.theme.screen.sm}) {
    grid-template-columns: 1fr;
    padding: 0 64px;
  }
`

const FeatureItem = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
`

const FeatureTitle = styled.h4`
  color: ${props => props.theme.color.primary};
  letter-spacing: 0px;
  line-height: 30px;
  margin-bottom: 10px;
  margin-block-start: 0;
`

const FeatureText = styled.p`
  text-align: center;
  margin-block-start: 0;
`

const ImageWrapper = styled.div`
  justify-self: center;
  align-self: center;
`

const StyledImage = styled(Img)`
  width: 100px;
  display: block;
  margin-left: auto;
  margin-right: auto;
`

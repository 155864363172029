import React from "react"
import styled from "styled-components"

import { Section, Container } from "../global"

const Features = () => (
  <Section id="use cases">
    <StyledContainer>
      <Subtitle></Subtitle>
      <SectionTitle>Use Cases</SectionTitle>
      <FeaturesGrid>
        <FeatureItem>
          <FeatureTitle>Budgeting</FeatureTitle>
          <FeatureText>
            Budgets require input from many people and
            departments. Allow people to read and contribute to just
            the budget areas they should be involved with. Prevent accidental
            disclosure of controversial budget information before it's ready.
          </FeatureText>
        </FeatureItem>
        <FeatureItem>
          <FeatureTitle>Contracts</FeatureTitle>
          <FeatureText>
            Developing contracts requires collaboration between lawyers and
            business stakeholders. Simplify the review of complex agreements
            by tracking and reporting the complete history of edits and comments.
          </FeatureText>
        </FeatureItem>
        <FeatureItem>
          <FeatureTitle>Government</FeatureTitle>
          <FeatureText>
            Develop policies, budgets, regulations and legislation with strict need-to-know
            security. Allow individuals to read and contribute to just sections they
            need to know, with no other access. Keep all information non-public until it's ready.
          </FeatureText>
        </FeatureItem>
        <FeatureItem>
          <FeatureTitle>Bid & Proposal</FeatureTitle>
          <FeatureText>
            Develop confidential bids and proposals in an extended team. Easily reuse
            proven and approved boilerplate content. Ensure strict versioning and review processes.
            Align proposal development with Shipley Method process milestones.
          </FeatureText>
        </FeatureItem>
        <FeatureItem>
          <FeatureTitle>Engineering</FeatureTitle>
          <FeatureText>
            Engineering requires collaboration and responsibility. Enable
            your employees and suppliers to work together on design specs. Know which supplier
            or individual wrote, read, reviewed and approved each separate engineering decision.
          </FeatureText>
        </FeatureItem>
        <FeatureItem>
          <FeatureTitle>Investment</FeatureTitle>
          <FeatureText>
            Develop confidential prospectuses and finance agreements
            securely. Know who read, wrote, reviewed and approved
            what content. Be confident of every edit when developing
            complex documents. Ensure regulatory compliance.
          </FeatureText>
        </FeatureItem>
        <FeatureItem>
          <FeatureTitle>Education</FeatureTitle>
          <FeatureText>
            For research groups and collaborative projects, track and report
            each person's contribution. Review all comments and
            changes, and attribute content to its contributors.
            Enable student-teacher and groupp collaboration.
          </FeatureText>
        </FeatureItem>
        <FeatureItem>
          <FeatureTitle>Banking</FeatureTitle>
          <FeatureText>
            Develop and maintain bank policies and procedures. Manage the review and
            approval process in an extended team.
            Enable collaboration and review in the assembly of loan packages.
          </FeatureText>
        </FeatureItem>
        <FeatureItem>
          <FeatureTitle>Life Sciences</FeatureTitle>
          <FeatureText>
            Enable contuinity of documentation in extended teams.
            Use a standard approved content library to ensure consistent labeling across
            all products. Ensure regulatory compliance in
          </FeatureText>
        </FeatureItem>
      </FeaturesGrid>
    </StyledContainer>
  </Section>
)

export default Features

const StyledContainer = styled(Container)`
  padding-bottom: 20px;
`

const SectionTitle = styled.h3`
  color: ${props => props.theme.color.primary};
  display: flex;
  justify-content: center;
  margin: 0 auto 40px;
  text-align: center;
`

const Subtitle = styled.h5`
  font-size: 16px;
  color: ${props => props.theme.color.accent};
  letter-spacing: 0px;
  margin-bottom: 12px;
  text-align: center;
`

const FeaturesGrid = styled.div`
  max-width: 670px;
  display: grid;
  grid-template-columns: 1fr 1fr;
  margin: 0px auto;
  grid-column-gap: 40px;
  grid-row-gap: 35px;
  @media (max-width: ${props => props.theme.screen.sm}) {
    grid-template-columns: 1fr;
    padding: 0 64px;
  }
`

const FeatureItem = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
`

const FeatureTitle = styled.h4`
  color: ${props => props.theme.color.primary};
  letter-spacing: 0px;
  line-height: 30px;
  margin-bottom: 10px;
  margin-block-start: 0;
`

const FeatureText = styled.p`
  text-align: center;
  margin-block-start: 0;
`
